import { graphql, navigate } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import { PublicSale } from "../containers/PublicSale";
import pages from "./pages.json";
import { useEffect } from "react";

const PublicPreSalePage = () => {
  return (
    <>
      <Helmet>
        <title>{pages.publicSale.title}</title>
      </Helmet>

      <Layout>
        <PublicSale />
      </Layout>
    </>
  );
};

//export default PublicPreSalePage;
export default () => {
  useEffect(() => {
    navigate('/404');
  }, []);
  return null;
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
